import * as Sentry from '@sentry/nextjs';

export const configureSentry = ({ dsn }) => {
  let environment;
  if (process.env.SERVER_TYPE === 'dev') environment = 'development';
  if (process.env.SERVER_TYPE === 'prod') environment = 'production';

  if (process.env.IS_LOCAL === 'true') {
    return;
  }
  return Sentry.init({
    dsn,
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
    environment
  });
};