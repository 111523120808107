import React, { useEffect, useState, useContext } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import FixFooter from "../CommonBlocks/FixFooter/FixFooter";
import { AppProvider, AppContext } from "../../context/appState";
import "./styles/main.scss";
import { Loader } from "../../components/HelperComponents/Loader/Loader";
import { useRouter } from "next/router";

const Layout = props => {
    const { title = "", description = "", keywords = "", pageClasses = "relative_wrapper" } = props;
    const [useOverlay, setUseOverlay] = useState(false);
    const router = useRouter();
    const { setLoader, loader, dispatch } = useContext(AppContext);
    useEffect(() => {
        setTimeout(() => {
            setLoader(false, dispatch);
        }, 2000);
        makeListeners();
        router.beforePopState(({ as }) => {
            if (as !== router.asPath) {
                setLoader(true, dispatch);
            }
            return true;
        });
        return () => {
            router.beforePopState(() => true);
        };
    }, [router]);

    const makeListeners = () => {
        // let elements = document.getElementsByTagName("a:not(.no_trigger)"),
        //     catalog = document.querySelectorAll(".catalog-dropdown .root *"),
        //     exceptions = document.querySelectorAll(".exception:not(.no_trigger)");
        let links = [...document.querySelectorAll("a:not(.no_trigger):not(.bingc-active-close-button)")];
        links.forEach(link => {
            link.onclick = () => {
                if ((link.origin && link.baseURI && router.asPath && link.origin + router.asPath === link.href) ||
                    link.target === "_blank" ||
                    (link.href && link.href.includes("tel:")) ||
                    (link.href && link.href.includes("mailto:"))
                ) {
                    setLoader(false, dispatch)
                } else {
                    setLoader(true, dispatch);
                }
            };
        })
    }

    return (
        <div className={pageClasses}>
            <Header
                temp_title={title === "" ? "Строительный интернет-магазин │ PRORAB" : title}
                temp_description={description}
                temp_keywords={keywords}
                categories={props.categories}
                setUseOverlay={setUseOverlay}
                makeListeners={makeListeners}
                loader={loader}
                shopList={props.shopList}
                headerDeliverySnippet={props.headerDeliverySnippet}
                headerDeliveryMobSnippet={props.headerDeliveryMobSnippet}
            />
            <div
                className="overlay"
                style={{ display: useOverlay ? "block" : "none" }}
                onClick={() => setUseOverlay(false)}
            />
            {false
                ? <Loader />
                : router.asPath.includes('/categories/')
                    ? <div className="categories_page_loader_wrapper">
                        <div className="content_block page_wrapper">{props.childComponentMakeListeners(makeListeners)}</div>
                    </div>
                    : <div className="content_block page_wrapper">{props.childComponentMakeListeners(makeListeners)}</div>
            }
            <Footer categories={props.categories} shopList={props.shopList} />

            <FixFooter loader={loader} />
        </div >
    );
};

export default Layout;
