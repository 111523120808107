import React, { useContext, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";

import "./FixFooter.scss";
import cart_img from "../../../public/images/cart_alt.svg";

import { AppContext } from "../../../context/appState";

import { withTranslation, Link } from "../../../i18n";

import LinearProgress from "@material-ui/core/LinearProgress";
import phone_icon from "../../../public/images/phone_btn_new.svg";
import DialogComponent from "../../HelperComponents/DialogComponent/DialogComponent";
import close from "../../../public/images/close_alt.svg";
import phone from "../../../public/images/header-phone.svg";

const FixFooter = props => {
    const [loading, setLoading] = useState(true);
    const { total_sum, total_quantity, favorites_list } = useContext(AppContext);
    const [windowOffset, setWindowOffset] = useState(0);
    const prevPropsRef = useRef();
    const prevProps = prevPropsRef.current;
    let timer = undefined;
    const router = useRouter();
    useEffect(() => {
        setLoading(false);
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setWindowOffset(window.pageYOffset);
        });
    }, []);

    useEffect(
        () => () => {
            clearTimeout(timer);
            prevPropsRef.current = favorites_list;
        },
        []
    );

    useEffect(() => {
        prevPropsRef.current = favorites_list;
        if (!loading) {
            if ((prevProps[0] && !prevProps[0].loading) || !prevProps[0]) {
                if (prevProps.length > favorites_list.length) {
                    let remove_announcer = document.getElementById("remove_announcer");
                    remove_announcer.style.display = "block";
                    timer = setTimeout(() => (remove_announcer.style.display = "none"), 1000);
                }
                if (prevProps.length <= favorites_list.length) {
                    let add_announcer = document.getElementById("add_announcer");
                    add_announcer.style.display = "block";
                    timer = setTimeout(() => (add_announcer.style.display = "none"), 1000);
                }
            }
        }
    }, [favorites_list]);

    const ScrollTop = () => {
        window.scrollTo(0, 0);
    };

    const { t } = props;
    const toggleDialogPhone = () => setState({ ...state, dialogOpenedPhone: !state.dialogOpenedPhone });
    const [state, setState] = useState({ dialogOpenedPhone: false, });
    return (
        <div className="fix_footer_wrapper">
            {/* {props.loader && <LinearProgress color={`secondary`} />} */}
            <div className="content_block fix_footer_block">
                <div className={`page_link${router.asPath.includes("favorite") ? " active" : ""}`}>
                    <Link href="/favorite" prefetch={false}>
                        <div className="fa_heart_o">
                            <p className="font_awesome"></p>
                            <a>{t("Избранное")}</a>
                            {favorites_list[0] && !favorites_list[0].loading ? (
                                <span>{favorites_list.length}</span>
                            ) : (
                                <span>0</span>
                            )}
                            <div id="add_announcer" className="announcer">
                                {t("Добавлено в избранные")}
                            </div>
                            <div id="remove_announcer" className="announcer">
                                {t("Удалено из избранного")}
                            </div>{" "}
                        </div>
                    </Link>
                </div>

                <div className={`basket_info_wrapper${router.asPath.includes("cart") ? " active" : ""}`}>
                    <Link href="/cart">
                        <div className="fa-shopping-cart basket_mob ">
                            <p className="font_awesome"></p>
                            <a>{t("Корзина")}</a>
                            <span>{total_quantity ? total_quantity : 0}</span>
                        </div>
                    </Link>

                    <Link href="/cart">
                        <a className="basket_info_wrapper">
                            <p>
                                <img src={cart_img} alt="PRORAB корзина image" title="PRORAB" />
                            </p>
                            <div>{total_quantity ? total_quantity : 0}</div>
                            <span>{total_sum ? total_sum : 0} грн.</span>
                        </a>
                    </Link>
                </div>
                <button className="btn_top" onClick={ScrollTop} style={windowOffset > 0 ? {} : { display: "none" }}>
                    <span className="font_awesome"></span>
                </button>
            </div>
            <div className="fixed_wrapper">
                <div className='phone_btn' onClick={toggleDialogPhone}>
                    <img src={phone_icon} alt="phone_icon" />
                </div>
                <div className="btn_fix">
                    <button className="mob_btn" onClick={ScrollTop}>
                        <span className="font_awesome"></span>
                    </button>
                </div>
            </div>

            <DialogComponent
                open={state.dialogOpenedPhone}
                onClose={toggleDialogPhone}
                classes="dialog_phone_wrapper"
                paper_classes="dialog_callback_phone"
                close_image={close}
            >
                <div className="dialog_phone">
                    <div className="dialog_title">{t("Наши контакты")}</div>
                    <hr/>
                    <span>{t("Горячая линия")}</span>
                    <div className="free">
                        <img src={phone} alt="Иконка телефона" />
                        <a href="tel:0800330757">0 800 33 07 57</a>
                    </div>
                    <span>{t("Бесплатно с мобильного")}</span>
                    <div>
                        <img src={phone} alt="Иконка телефона" />
                        <a href="tel:0997730444">099 773 04 44</a>
                    </div>
                    <div>
                        <img src={phone} alt="Иконка телефона" />
                        <a href="tel:0977730444">097 773 04 44</a>
                    </div>
                </div>
            </DialogComponent>
        </div>
    );
};

export default withTranslation("footer")(FixFooter);
