import React from "react";
import telegram_footer from "../../../public/images/telegram_footer.png";
import viber_footer from "../../../public/images/viber_footer.png";
import address_footer from "../../../public/images/address_footer.png";
import mail_footer from "../../../public/images/mail_footer.png";
import inst_footer from "../../../public/images/inst_footer.png";
import fb_footer from "../../../public/images/fb_footer.png";
import delivery_footer from "../../../public/images/delivery_footer.png";
import phone_footer_icon from "../../../public/images/phone_footer.svg";
import liqpay from "../../../public/images/footer2.svg";
import alpha from "../../../public/images/footer4.svg";
import privat from "../../../public/images/footer3.svg";
import visa_card from "../../../public/images/footer1.svg";
import "./Footer.scss";

import Advantages from "./Advantages";

import { withTranslation, Link, i18n } from "../../../i18n";
import { CLEAN_API_URL } from "../../../context/appState";

const Footer = props => {
    const { t, shopList } = props;

    const { language } = i18n;
    //console.log(props.categories)
    return (
        <footer className="footer">
            <Advantages />
            <div className="content_block footer_content">
                <div className="categories_footer">
                    <div className="title_footer">{t("Категории")}</div>
                    {props.categories.map(({ icon, slug, name_ua, name }, idx) => (
                        <Link key={slug + idx} href={`/categories/${slug}`}>
                            <a rel="nofollow" key={idx}>
                                <p>
                                    <img src={icon?.includes(CLEAN_API_URL) ? icon : CLEAN_API_URL + icon} alt={slug} />
                                </p>
                                {language === "ua" ? name_ua : name}
                            </a>
                        </Link>
                    ))}
                </div>
                <div className="services_footer">
                    <div className="title_footer">{t("Услуги")}</div>
                    <Link href={`/uslugi#remont`}>
                        <a rel="nofollow">
                            <span>{t("Ремонтно-строительные работы")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#montaj`}>
                        <a rel="nofollow">
                            <span>{t("Монтаж натяжных потолков")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#delivery`}>
                        <a rel="nofollow">
                            <span>{t("Доставка товаров")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#movers`}>
                        <a rel="nofollow">
                            <span>{t("Услуги грузчиков")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#garbage`}>
                        <a rel="nofollow">
                            <span>{t("Вывоз строительного мусора")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#paint`}>
                        <a rel="nofollow">
                            <span>
                                {t("Колеровка красок и декоративных")} <br /> {t("штукатурок")}
                            </span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#manipulyator`}>
                        <a rel="nofollow">
                            <span>{t("Услуги манипулятора")}</span>
                        </a>
                    </Link>
                    <Link href={`/uslugi#individual_manager`}>
                        <a rel="nofollow">
                            <span>{t("Индивидуальный менеджер")}</span>
                        </a>
                    </Link>
                </div>
                <div className="about_company">
                    <div className="title_footer">{t("О Компании")}</div>
                    <Link href="/o-nas">
                        <a rel="nofollow" className={`footer_no_stylish_link`}>
                            <span>{t("О нас")}</span>
                        </a>
                    </Link>
                    <Link href="/oplata-i-dostavka">
                        <a rel="nofollow" className={`footer_no_stylish_link`}>
                            <span>{t("Оплата и доставка")}</span>
                        </a>
                    </Link>
                    <Link href="/installment">
                        <a rel="nofollow" className={`footer_no_stylish_link`}>
                            <span>{t("Рассрочка")}</span>
                        </a>
                    </Link>
                    <Link href="/usloviya-vozvrata-tovara">
                        <a rel="nofollow" className={`footer_no_stylish_link`}>
                            <span>{t("Условия возврата товара")}</span>
                        </a>
                    </Link>
                    <Link href="/site-map">
                        <a rel="nofollow" className={`footer_no_stylish_link`}>
                            <span>{t("Карта сайта")}</span>
                        </a>
                    </Link>
                    <a rel="nofollow" href={`https://t.me/prorab2020`} target="_blank" className="telegram_btn big_btn">
                        <img src={telegram_footer} alt="porab.group telegram" />
                        <span>{t("Написать нам")}</span> <p>{t("Написать")}</p>
                    </a>
                    <a
                        rel="nofollow"
                        href={`https://invite.viber.com/?g2=AQBEyr1p48RkA0uzADirRXp2acQaNIWJ3iG0yh4j%2B%2BLQdCkBsn1VDmtWR4kjgEi0&lang=ru`}
                        target="_blank"
                        className="viber_btn big_btn"
                    >
                        <img src={viber_footer} alt="porab.group viber" />
                        <span>{t("Написать нам")}</span> <p>{t("Написать")}</p>
                    </a>
                </div>
                <div className="info_address">
                    <div className="title_footer">{t("Звоните, пишите или приезжайте")}</div>
                    <div className="address">
                        <img src={address_footer} alt="prorab.group adress" />
                        <div>
                            {shopList.map((shop, idx) => (
                                <Link key={shop.address + idx} href={`/kontakty?map=${idx}`}>
                                    <a rel="nofollow" className={`footer_no_stylish_link`}>
                                        <span>{language === "ua" ? shop.address_ua : shop.address}</span>
                                    </a>
                                </Link>
                            ))}
                        </div>
                    </div>
                    <a rel="nofollow" href="mailto:poltava.prorab@gmail.com" className="mail">
                        <img src={mail_footer} alt="porab.group email" />
                        poltava.prorab@gmail.com
                    </a>
                    <a rel="nofollow" href="tel:0800330757" className="phone_footer">
                        <img src={phone_footer_icon} alt="porab.group phone" width="22px" height="22px" />
                        <div>
                            <span className={`binct-phone-number-1`}>0 800 33 07 57</span>
                            <p>{t("Бесплатно с мобильного")}</p>
                        </div>
                    </a>
                    <a rel="nofollow" href="tel:0997730444" className="phone_footer no-image">
                        <div>
                            <span className={`binct-phone-number-1`}>099 773 04 44</span>
                        </div>
                    </a>
                    <a rel="nofollow" href="tel:0977730444" className="phone_footer no-image mb-30">
                        <div>
                            <span className={`binct-phone-number-1`}>097 773 04 44</span>
                        </div>
                    </a>
                    <Link href={`/oplata-i-dostavka#delivery_anchor`}>
                        <a rel="nofollow" className="delivery_btn big_btn">
                            <img src={delivery_footer} alt="prorab.group delivery" />
                            {t("Доставка по Украине")}
                        </a>
                    </Link>
                    <div className="social">
                        <a rel="nofollow" href="https://www.facebook.com/prorab.sale/" target="_blank">
                            <img src={fb_footer} alt="porab.group Facebook" />
                        </a>
                        <a rel="nofollow" href="https://www.instagram.com/prorab.group/" target="_blank">
                            <img src={inst_footer} alt="porab.group Instagram" />
                        </a>
                    </div>
                </div>
            </div>
            <div className="card_info_footer">
                <div>
                    <img src={visa_card} alt="porab.group visa pay" />
                </div>
                <div>
                    <img src={liqpay} alt="porab.group liqpay" />
                </div>
                <div>
                    <img src={privat} alt="porab.group приватбанк" />
                </div>
                <div>
                    <img src={alpha} alt="porab.group альфабанк" />
                </div>
            </div>
        </footer>
    );
};

export default withTranslation("footer")(Footer);
