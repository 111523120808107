import React from "react";
import { withTranslation } from "../../../i18n";
import box from "../../../public/images/advantages-box.svg";
import timer from "../../../public/images/advantages-timer.svg";
import man from "../../../public/images/advantages-man.svg";
import money from "../../../public/images/advantages-money.svg";

import "./Footer.scss";

const Advantages = ({ t }) => {
    return (
        <div className="content_block footer-advantages">
            <div className="advantages-first-row">
                <div>
                    <div>
                        <img src={box} alt="advantages-box" />
                        <span>{t("Комплектаця")}</span>
                        <p>{t("Ремонтные")}</p>
                    </div>
                    <div>
                        <img src={timer} alt="advantages-box" />
                        <span>{t("Експрес доставка")}</span>
                        <p>{t("быстрая доставка")}</p>
                    </div>
                </div>
                <div>
                    <div>
                        <img src={man} alt="advantages-box" />
                        <span>{t("Ваш персональний менеджер")}</span>
                        <p>{t("свой человек")}</p>
                    </div>
                    <div>
                        <img src={money} alt="advantages-box" />
                        <span>{t("Зарабатываем")}</span>
                        <p>{t("партнерская")}</p>
                    </div>
                </div>
            </div>
            <div className="advantages-second-row">
                <div className="border_block">
                    <div className="color">{t("Компаний")}</div>
                    <div className="icon">130</div>
                </div>
                <div className="border_block">
                    <div className="color">{t("Товаров")}</div>
                    <div className="icon">25 000</div>
                </div>
                <div className="border_block">
                    <div className="color">{t("Клиентов")}</div>
                    <div className="icon">350</div>
                </div>
            </div>
        </div>
    );
};

export default withTranslation("footer")(Advantages);
