export const emailRegExp = new RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,8}$/i);

export const arrayOfLetters = [
    "q",
    "w",
    "e",
    "r",
    "t",
    "y",
    "u",
    "i",
    "o",
    "p",
    "a",
    "s",
    "d",
    "f",
    "g",
    "h",
    "j",
    "k",
    "l",
    "z",
    "x",
    "c",
    "v",
    "b",
    "n",
    "m"
];

export const onlyLetterRegExp = /^[a-zа-яA-ZА-ЯЁёІіЇїЄє\s]*$/i;

export const onlyDigitsRegExp = /^[0-9+]+$/i;

export const phoneRegExp = /^(0[5-9][0-9]\d{7})$/;

export const fullPhoneRegExp = /^\+?3?8?(0[5-9][0-9]\d{7})$/;

export const codeRegExp = /^[0-9]{6}$/;
