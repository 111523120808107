import Layout from "../components/Layout/Container";
import App from "next/app";
import { appWithTranslation } from "../i18n";
import { API_BASE_URL } from "../context/appState";
import { AppProvider } from "../context/appState";

function MyApp({ Component, pageProps, categories, shopList, headerDeliverySnippet, headerDeliveryMobSnippet }) {
    const { title, description, keywords, pageClasses/* , namespacesRequired */ } = pageProps;
    return (
        <AppProvider>
            <Layout
                categories={categories}
                title={title}
                description={description}
                keywords={keywords}
                pageClasses={pageClasses}
                shopList={shopList}
                headerDeliverySnippet={headerDeliverySnippet}
                headerDeliveryMobSnippet={headerDeliveryMobSnippet}
                childComponentMakeListeners={makeListeners => (
                    <Component
                        {...pageProps}
                        makeListeners={makeListeners}
                    />
                )}
            />
        </AppProvider>
    );
}

MyApp.getInitialProps = async appContext => {
    const appProps = await App.getInitialProps(appContext);
    let res = await fetch(`${API_BASE_URL}/category/`);
    const json = await res.json();
    let shopList = await fetch(`${API_BASE_URL}/shop-list/`).then(res => res.json());
    const headerDeliverySnippet = await fetch(`${API_BASE_URL}/snippet/${1}/`).then(res => res.json())
    const headerDeliveryMobSnippet = await fetch(`${API_BASE_URL}/snippet/${2}/`).then(res => res.json())
    return {
        pageProps: { ...appProps.pageProps, namespacesRequired: ["header", "footer", "error", ...(appProps.pageProps.namespacesRequired ? appProps.pageProps.namespacesRequired : [])] },
        categories: json,
        shopList: shopList,
        headerDeliverySnippet: headerDeliverySnippet,
        headerDeliveryMobSnippet: headerDeliveryMobSnippet
    };
};

export default appWithTranslation(MyApp);
