import React from "react";

export default function Arrow({ red = false }) {
    return (
        <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.59998 11.8L6.39997 7.00005L1.59998 2.20005"
                stroke={"#4A5568"}
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
                opacity="0.6"
            />
        </svg>
    );
}
